// @flow
import React, { Suspense } from 'react';
import fscreen from 'fscreen';
import ErrorBoundary from 'react-error-boundary';
import styled, { createGlobalStyle } from 'styled-components';
import 'normalize.css';

import SEO from '../components/SEO';
import Logo from '../components/Logo';

const YoutubePlayer = React.lazy(() => import('../components/YoutubePlayer'));
const Sidebar = React.lazy(() => import('../components/Sidebar'));

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
    font-family: -apple-system,BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #fff;
    background-color: #000;
    overflow-x: hidden;
  }
`;

const Page = styled.div`
`;

const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  z-index: 1;
`;

const Main = styled.div`
  position: relative;
  height: 100vh;
`;

const Footer = styled.footer`
  display: flex;
  padding: 24px 15px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #999;
  background: #fff;
`;

const Copyright = styled.p`
  margin: 0;
`;

const FooterMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const FooterMenuItem = styled.a`
  display: inline-block;
  color: inherit;

  + a {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #e5e5e5;
  }
`;

type Props = {};

type State = {
  isSyncing: boolean,
  isWatching: boolean,
  isFullscreen: boolean,
  isSidebarOpen: boolean,
}

class Index extends React.PureComponent<Props, State> {
  state = {
    isSyncing: true,
    isWatching: false,
    isFullscreen: false,
    isSidebarOpen: false,
  };

  componentDidMount() {
    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener('fullscreenchange', this.handleFullscreenChange, false);
    }
  }

  componentWillUnmount() {
    if (fscreen.fullscreenEnabled) {
      fscreen.removeEventListener('fullscreenchange', this.handleFullscreenChange, false);
    }
  }

  handleFullscreenChange = () => {
    this.setState({
      isFullscreen: fscreen.fullscreenElement !== null,
    });
  }

  handleFullscreenToggle = () => {
    const { isFullscreen } = this.state;

    if (isFullscreen) {
      fscreen.exitFullscreen();
    } else {
      fscreen.requestFullscreen(document.documentElement);
    }

    this.setState({ isFullscreen: !isFullscreen });
  }

  handleWatchNowClick = () => {
    this.setState({ isWatching: true });
  }

  handlePlayerReady = () => {
    this.setState({ isSyncing: false });
  }

  handleSidebarToggle = () => {
    this.setState(({ isSidebarOpen }) => ({ isSidebarOpen: !isSidebarOpen }));
  }

  handleMainClick = () => {
    const { isSidebarOpen } = this.state;

    if (!isSidebarOpen) {
      return;
    }

    this.setState({ isSidebarOpen: false });
  }

  render() {
    const {
      isSyncing, isWatching, isSidebarOpen, isFullscreen,
    } = this.state;

    const launchYear = 2019;
    const currentYear = (new Date()).getFullYear();
    const copyrightYearText = launchYear === currentYear
      ? launchYear
      : `${launchYear} - ${currentYear}`;
    const copyright = `Copyright © ${copyrightYearText} Plum Village.`;

    return (
      <Page>
        <SEO
          title="Plum Village TV"
          description="24/7 Plum Village TV"
        />
        <GlobalStyle />
        <Header>
          <Logo />
        </Header>
        <Main onClick={this.handleMainClick}>
          {typeof window !== 'undefined' && ( // Fixes Gatsby error Building static HTML for pages failed
            <ErrorBoundary>
              <Suspense fallback={null}>
                <YoutubePlayer
                  isSyncing={isSyncing}
                  isWatching={isWatching}
                  isFullscreen={isFullscreen}
                  onWatchNowClick={this.handleWatchNowClick}
                  onPlayerReady={this.handlePlayerReady}
                  onFullscreenToggle={this.handleFullscreenToggle}
                />
              </Suspense>
            </ErrorBoundary>
          )}
        </Main>
        {isWatching && typeof window !== 'undefined' && (
          <ErrorBoundary FallbackComponent="span">
            <Suspense fallback={null}>
              <Sidebar
                isOpen={isSidebarOpen}
                onToggle={this.handleSidebarToggle}
              />
            </Suspense>
          </ErrorBoundary>
        )}
        <Footer>
          <Copyright>{copyright}</Copyright>
          <FooterMenu>
            <FooterMenuItem
              href="https://www.iubenda.com/privacy-policy/50183133"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              Privacy Policy
            </FooterMenuItem>
            <FooterMenuItem
              href="mailto:hello@plumvillage.tv"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              Contact
            </FooterMenuItem>
          </FooterMenu>
        </Footer>
      </Page>
    );
  }
}

export default Index;
